body,
.custom-font-02,
input,
textarea {
  font-family: "Montserrat", "Arial", sans-serif !important;
}

.navbar-light.dark .sticky .sticky-area {
  background-color: #0f1214 !important;
}

.navbar-fixed .sticky-area {
  background-color: #0f1214 !important;
}

.navbar-light.dark .navbar-nav .nav-link,
.navbar-light.dark .nav-search,
.navbar-light.dark .navbar-toggler,
.navbar-light.dark .navbar-nav .nav-link:hover {
  color: #fff !important;
}

.main-header .main-menu .nav-link::before,
.main-header .main-menu .dropdown-header::before {
  background-color: #fff;
}

.navbar-light.dark .navbar-nav .show > .nav-link,
.navbar-light.dark .navbar-nav .active > .nav-link,
.navbar-light.dark .navbar-nav .nav-link.show,
.navbar-light.dark .navbar-nav .nav-link.active {
  color: #fff;
}

.main-header .navbar-right .number {
  background-color: #fff;
  color: #231f20;
}

.navbar-brand img {
  width: 120px;
}

@media (min-width: 768px) {
  .banner-10 .card-img {
    height: 400px !important;
  }
}

.bg-img-cover-rounded {
  border-radius: 20px;
}

.border-radius {
  border-radius: 10px;
}

.text-light a {
  color: #fff;
}

.navbar-light .dropdown-menu {
  background-color: #0f1214;
}

.main-header .navbar .dropdown-link:focus,
.main-header .navbar .dropdown-item:focus,
.main-header .navbar .dropdown-link:hover,
.main-header .navbar .dropdown-item:hover,
.main-header .navbar .dropdown-item.active,
.main-header .navbar .dropdown-item.active .dropdown-link {
  color: #fff;
}

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown:hover {
  border-color: #000 !important;
}

a {
  color: #000;
}

a:hover {
  text-decoration: underline;
}

.invalid-feedback {
  display: block;
}

.ant-form-item-explain-error {
  color: #bc4f38;
  font-size: 80%;
}

.ant-form-item-has-error .dropdown {
  border-color: #bc4f38 !important;
}

.ant-form-item-has-error .text,
.ant-form-item-has-error i {
  color: #bc4f38 !important;
}

.custom-dropdown {
  width: 100%;
}

.custom-dropdown .btn {
  border: 1px #0f1214 solid;
  padding: 5px 10px;
}

.custom-dropdown .dropdown-item {
  padding: 5px 10px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.custom-dropdown .dropdown-menu {
  padding: 0;
  margin: 0;
  width: 100%;
}

.custom-dropdown .dropdown-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.custom-dropdown .dropdown-toggle::after {
  display: flex;
  color: black;
  margin-right: 10px;
}

.custom-dropdown .dropdown-item.active,
.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: white;
}

.main-header .navbar .dropdown-link {
  color: #dedad6;
}

a {
  cursor: pointer;
}

.dots-light span {
  background: #fff;
}

.dots-light li.slick-active span {
  border-color: #fff;
}

.store-location {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.location-navigator {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.cart-canvas {
  max-width: 100%;
}

.smok-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
}

.smok-spinner {
  z-index: 9999999;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}

ol {
  padding-left: 18px;
}

.dropdown-image {
  flex: 0 0 60px;
}

.custom-dropdown-item {
  flex: 1;
  white-space: normal;
  height: "100px";
}

.ant-form-item-explain-error {
  margin-top: 10px;
}

.brand-gradient {
  background: rgb(0, 154, 247);
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 154, 247, 1) 0%,
    rgba(0, 221, 188, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#RFS-Label {
  font-weight: 500 !important;
  color: #343a40 !important;
  font-family: "Montserrat", "Arial", sans-serif !important;
}

#RFS-StepButton span {
  font-family: "Montserrat", "Arial", sans-serif !important;
}

.Toastify__toast-body {
  font-family: "Montserrat", "Arial", sans-serif !important;
}

.animation-opacity {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-tøransition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.modal-content {
  border: none;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%);
}

#RFS-StepContainer {
  min-width: 150px;
}

.coming-soon {
  position: relative;
  background-color: #0b011a;
  height: 100vh;
  margin: 0;
}

.coming-soon img {
  position: absolute;
}

.coming-soon .center {
  text-align: center;
  left: calc(50% - 160px);
  height: 300px;
  max-width: 320px;
  font-size: 24px;
}

.coming-soon .top-corner {
  top: 0;
  left: 0;
  height: 350px;
}

.coming-soon .bottom-corner {
  right: 0;
  bottom: 0;
  height: 450px;
}

.coming-soon .brand {
  left: 0;
  bottom: 0;
  height: 50px;
  padding: 40px;
}

.coming-soon .logo {
  right: 0;
  top: 0;
  height: 50px;
  padding: 40px;
}

.coming-soon h1 {
  position: absolute;
  z-index: 1000;
  top: calc(50% - 41px);
  left: calc(50% - 25px);
  margin: 0;
  font-size: 72px;
  background: rgb(0, 154, 247);
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 154, 247, 1) 0%,
    rgba(0, 221, 188, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat", "Arial", sans-serif !important;
  font-style: italic;
}

.custom-control-label:before {
  border-color: #000;
}

.badge-primary {
  background-color: #ff2d55;
}

.sk-overlay {
  top: 0;
  left: 0;
  position: absolute;
  display: none;
  color: white;
}

.sk-image-overlay {
  position: relative;
}

.sk-image-overlay:hover .sk-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: block;
}

.sk-overlay .overlay-text {
  font-size: 10px;
  font-weight: 400;
}
